// overriding bootstrap variables in _boostrap-override.scss
$family-base: 'proxima-nova', sans-serif;
$family-header: 'proxima-nova', serif;

// font-size
$font-size-desktop: 21px;
$font-size-mobile: 16px;

$weight-thin: 100;
$weight-light: 300;
$weight-regular: 400;
$weight-normal: $weight-light;
$weight-bold: 700;

// colors - sister colours from bootrap
$color-red: #ea3323;
$color-blue: #007bff;
$color-orange: #fd7e14;
$color-green: #28a745;

// more colors
$color-dark-red: #a72418;
$color-dark-grey: #2b2b2b;
$color-gold: #faa222;
$color-mid-blue: #5c6594;
$color-dark-blue: #323c50;

// site colors
$primary-color: $color-red;
$font-color: $color-dark-blue;
$font-color-white: #fff;
$header-color: $primary-color;
$bg-color: #fff;
$link-color: $primary-color;
$link-hover-color: $color-dark-red;

// top-bar
$top-bar-border: (
  mobile: 5px,
  desktop: 12px,
);
$top-bar-height: (
  mobile: 44px,
  small-device: 56px,
  tablet: 56px,
  desktop: 56px,
);

// base transition
$transition-base: all 0.3s ease-in-out;
$transition-fast: all 0.2s ease-in-out;
$transition-slow: all 0.5s ease-in-out;
