@import './mixins';
@import './variables';

// plugins
@import './bootstrap/bootstrap';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
//@import '~react-day-picker/lib/style.css';
@import '~react-modal-video/scss/modal-video.scss';

//@import './fonts';
@import './base';
@import './sections';
@import './form';

// top
//@import './layout/hamburger';
//@import './layout/top';
@import './layout/top-navbar';

// bottom
@import './layout/bottomPanel';
@import './layout/bottomMenu';

@import './cms/cms-site';

// modules
@import './modules/breadcrumb';
@import './modules/rotatingBanner';
@import './modules/bannerSize';
@import './modules/pageTiles';
//@import './modules/blogTiles';
@import './modules/slider';
@import './modules/multiColumnPanel';

// components
@import '../components';

@import './site/index';

//aos
@import '~aos/src/sass/aos';

@import './youtubeNew.scss';

@media print {
  @import './print';
}

// react-image-gallery
@import '~react-image-gallery/styles/css/image-gallery.css';

//banner-text-bar
section.banner-text-bar {
  .htmlPanel {
    p {
      margin-bottom: 0px;
      text-align: center;
    }
  }
}

.choose-file-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  label.choose-file-label {
    background-color: white;
    margin-bottom: 0px !important;
    //font-family: $family-button;
    padding: 0.3rem 0.5rem;
    border-radius: 0.25rem;
    font-weight: 600;
    //color: #444;
  }
  div.file__attach__text {
    margin-left: 1rem;
    //color: white;
  }
}

body:not(.cmsedit) {
  div.cms_item.desktop-ig-feed {
    display: none;

    @include media-breakpoint-up(sm) {
      display: block;
    }
  }

  div.cms_item.mobile-ig-feed {
    display: block;

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

// react-image-gallery
.photoSlide .image-gallery-right-nav,
.photoSlide .image-gallery-left-nav {
  padding: 10px 10px;
  background-color: rgba(0, 0, 0, 0.4);
}
.photoSlide .image-gallery-left-nav .image-gallery-svg,
.photoSlide .image-gallery-right-nav .image-gallery-svg {
  width: 32px;
  height: 51px;
  @media screen and (max-width: 576px) {
    width: 13px;
    height: 32px;
  }
}
.photoSlide .image-gallery-slide .image-gallery-image {
  object-fit: cover;
  aspect-ratio: 1.7;
}
.image-gallery-thumbnail .image-gallery-thumbnail-image {
  aspect-ratio: 1.7;
}
.photoSlide .fslightbox-toolbar-button.fslightbox-flex-centered:first-child {
  display: none;
}
:not(:root):fullscreen::backdrop {
  height: 100%;
}
.fslightbox-open .cms_item.photoSlide {
  height: 100vh;
}
.fslightbox-open .cms_item.photoSlide .fslightbox-container {
  top: 81px;
  @media screen and (max-width: 576px) {
    top: 44px;
  }
}
.fslightbox-open .cms_item.photoSlide .fslightbox-container .fslightbox-source {
  transform: translateY(-57px);
}
