.bottomMenu {
  color: white;
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: black;
  ul,
  ol {
    list-style-type: none;
    padding-left: 0;
  }
  ul {
    display: flex;
    margin: auto;
    max-width: 400px;
    @include media-breakpoint-up(lg) {
      max-width: 650px;
    }
  }
  li {
    flex: 1 1 20%;
    text-align: center;
  }
}

.bottomMenu__li {
  transition: all 0.5s ease-out;
}

.bottomMenu__link {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 4px 5px;
  cursor: pointer;
  transition: all 0.3s ease-out;
  // font-family: $family-header;
  * {
    position: relative;
    z-index: 100;
  }
  .icon {
    width: 32px;
    height: 32px;
    margin: auto;
  }
  div {
    font-size: 8px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 1;
    margin-top: 0.3rem;
  }
  &:hover,
  &:focus {
    color: #ea3323;
    text-decoration: none;
    opacity: 1;
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  @include media-breakpoint-up(lg) {
    padding: 15px 5px;
    .icon {
      width: 40px;
      height: 40px;
    }
    div {
      font-size: 13px;
      letter-spacing: 0.5px;
    }
  }
}

.icon {
  img {
    width: 100%;
    height: 100%;
  }
  .normal {
    display: block;
  }
  .selected {
    display: none;
  }
}

.bottomMenu__link:hover .icon,
.bottomMenu__link.selected .icon {
  .normal {
    display: none;
  }
  .selected {
    display: block;
  }
}
.bottomMenu__links li.selected .bottomMenu__link {
  color: #ea3323;
  text-decoration: none;
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.bottomMenu .bottomMenu__links .selected .normal {
  display: none;
}
.bottomMenu .bottomMenu__links .selected .selected {
  display: block;
}

.bottomMenu hr {
  margin-top: 0;
  margin-bottom: 0;
  border-top: 6px solid #ea3323;
  @media screen and (min-width: 768px) {
    border-top: 8px solid #ea3323;
  }
}
